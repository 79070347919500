import apiService from '@/common/api.service'

export function getSpecifyist (id) {
  return apiService({
    url: '/api/cycle/list/specify',
    method: 'get',
    params: { id }
  })
}

export function getCycleById (id) {
  return apiService({
    url: '/api/cycle',
    method: 'get',
    params: { id }
  })
}

export function createCycle (data) {
  return apiService({
    url: '/api/cycle',
    method: 'post',
    data
  })
}
