const cycleTypeList = [
  { value: 0, text: 'Program' },
  { value: 10, text: 'Leakage Test' },
  { value: 11, text: 'Helix Test' },
  { value: 12, text: 'Bowie-Dick Test' },
  { value: 20, text: 'Water Level Sensor' },
  { value: 21, text: 'Air Filter' },
  { value: 22, text: 'Door Gasket' },
  { value: 23, text: 'Water Quality' },
  { value: 24, text: 'Safety Valve' },
  { value: 25, text: 'Temperature Calibration' },
  { value: 26, text: 'Steam Trap' },
  { value: 27, text: 'Steam Generator' },
  { value: 30, text: 'Drain Filter' },
  { value: 31, text: 'Shell' },
  { value: 32, text: 'Sterlilzation Tray Set' },
  { value: 33, text: 'Wipe Chamber' },
  { value: 34, text: 'Clean Chamber' },
  { value: 35, text: 'Clean Filter' },
  { value: 36, text: 'Clean Water Tank' },
  { value: 99, text: 'Other' }
]

export default cycleTypeList
