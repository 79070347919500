import apiService from '@/common/api.service'

export function getClinicList () {
  return apiService({
    url: '/api/clinic/list',
    method: 'get'
  })
}

export function getClinicSpecifyList (id) {
  return apiService({
    url: '/api/clinic/list/specify',
    method: 'get',
    params: { id }
  })
}

export function getClinicDetail (id) {
  return apiService({
    url: '/api/clinic',
    method: 'get',
    params: { id }
  })
}
