<template>
  <div>
    <div class="areawrap">
      <b-row>
        <b-col cols="5">
          <div class="d-flex align-items-end px-4">
            <h2 class="mb-0 mr-4">
              Corporate Information
            </h2>
            <span class="text-primary font-size-sm ml-auto">
              Date Added
            </span>
            <span class="font-size-base font-weight-normal ml-2">
              {{ $dayjs(clinicData.created_at).format('MMM D, YYYY') }}
            </span>
          </div>
          <hr>
          <b-form-group label="Clinic Name" label-class="text-primary mb-1">
            {{ clinicData.clinic_name }}

            <span
              v-if="clinicData.status === 0"
              class="d-inline-block bg-primary text-white rounded-pill px-2 py-0 ml-4"
            >
              Pending
            </span>
            <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0 ml-4">
              Active
            </span>
          </b-form-group>
          <b-form-group label="Address" label-class="text-primary mb-1">
            <span class="d-block">{{ clinicData.clinic_address }}</span>
            <span class="d-block">{{ clinicData.clinic_city }}, {{ country }}</span>
          </b-form-group>
          <b-form-group label="Telephone" label-class="text-primary mb-1">
            {{ clinicData.clinic_telphone }}
          </b-form-group>
          <b-form-group label="Facility" label-class="text-primary mb-1">
            {{ facility }}
          </b-form-group>
        </b-col>

        <b-col>
          <div class="d-flex align-items-end px-4">
            <h2 class="mb-0 mr-4">
              Owner Information
            </h2>
          </div>
          <hr>
          <b-form-group label="Owner" label-class="text-primary mb-1">
            {{ clinicData.owner_name || '-' }}
          </b-form-group>
          <b-form-group label="Email" label-class="text-primary mb-1">
            {{ clinicData.owner_email || '-' }}
          </b-form-group>
          <b-form-group label="Telephone" label-class="text-primary mb-1">
            {{ clinicData.owner_extension || '-' }}
          </b-form-group>
        </b-col>

        <b-col>
          <h2 class="px-4">
            Contact
          </h2>
          <hr>
          <b-form-group label="Contact" label-class="text-primary mb-1">
            {{ clinicData.contact_name || '-' }}
          </b-form-group>
          <b-form-group label="Email" label-class="text-primary mb-1">
            {{ clinicData.contact_email || '-' }}
          </b-form-group>
          <b-form-group label="Telephone" label-class="text-primary mb-1">
            {{ clinicData.contact_extension || '-' }}
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row>
      <b-col cols="4">
        <div class="areawrap">
          <b-row>
            <b-col class="mb-6">
              <h2>
                {{ currentAutoclave.serial_number }}
              </h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <b-form-group label="Model" label-class="text-primary mb-1">
                {{ currentAutoclave.model }}
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Warranty" label-class="text-primary mb-1">
                {{ currentAutoclave.warranty }}
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Product Status" label-class="text-primary mb-1">
                {{ emptyString(currentAutoclave.status) }}
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Count" label-class="text-primary mb-1">
                {{ emptyString(currentAutoclave.count) }}
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Station" label-class="text-primary mb-1">
                {{ emptyString(currentAutoclave.work_station) }}
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div
          v-if="autoclaveList.length > 0"
          class="custom-pagination d-flex justify-content-center hasprev"
          :class="{
            'hasnext': currentPage !== autoclaveList.length,
            'hasprev': currentPage !== 1
          }"
        >
          <b-pagination
            v-model="currentPage"
            pills
            hide-goto-end-buttons
            hide-ellipsis
            :total-rows="autoclaveList.length"
            :per-page="1"
            :limit="5"
            @change="onPageChange"
          >
            <template #page>
              <span />
            </template>
            <template #prev-text>
              <font-awesome-icon :icon="['fas', 'caret-left']" fixed-width />
            </template>
            <template #next-text>
              <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width />
            </template>
          </b-pagination>
        </div>
      </b-col>
      <b-col cols="8">
        <div class="areawrap" style="position: relative;">
          <template v-if="!selectedHistory">
            <b-row class="mb-4">
              <b-col cols="auto">
                <h2 class="mb-0">
                  Cycle History
                </h2>
              </b-col>
              <b-col cols="4" class="ml-auto">
                <b-form-datepicker v-model="searchDate" placeholder="Pick Dates" class="mb-2" />
              </b-col>
            </b-row>
            <div class="table-base">
              <b-table hover borderless :items="sortHistoryList" :fields="fields" @row-clicked="onRowSelected">
                <template #head(datetime)>
                  <div class="cursor-pointer" @click="sortList('end_date')">
                    END DATE
                    <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
                  </div>
                </template>
                <template #head(time)>
                  END TIME
                </template>
                <template #head(program_name)>
                  <div class="cursor-pointer" @click="sortList('program_name')">
                    CYCLE
                    <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
                  </div>
                </template>
                <template #head(status)>
                  <div class="cursor-pointer" @click="sortList('status')">
                    STATUS
                    <font-awesome-icon :icon="['fas', 'sort']" fixed-width />
                  </div>
                </template>
                <template #cell(datetime)="data">
                  {{ $dayjs(data.item.datetime).format('MMM D, YYYY') }}
                </template>
                <template #cell(time)="data">
                  {{ $dayjs(data.item.datetime).format('hh:mm A') }}
                </template>
                <template #cell(program_name)="data">
                  {{ getProgramName(data.item.type, data.item.program_name) }}
                </template>
                <template #cell(status)="data">
                  <span
                    v-if="data.item.status === 'Complete'"
                    class="d-inline-block bg-success text-white rounded-pill px-2 py-0"
                  >
                    Completed
                  </span>
                  <span v-else class="d-inline-block bg-danger text-white rounded-pill px-2 py-0">
                    {{ data.item.status }}
                  </span>
                </template>
              </b-table>
            </div>
          </template>
          <template v-else>
            <b-button
              variant="secondary"
              class="btn-action ml-5 mt-1 text-primary btn-back"
              @click="selectedHistory=null"
            >
              <font-awesome-icon :icon="['fas', 'arrow-left']" fixed-width />
            </b-button>
            <b-row>
              <b-col cols="12">
                <name-pagination
                  v-model="selectedHistoryIndex"
                  :name="historyDetailTitle"
                  :total="detailList.length"
                  @change="onHistoryDetailChange"
                />
              </b-col>
            </b-row>
            <hr>
            <b-row>
              <b-col cols="6">
                <h3 class="mb-4">
                  Cycle Information
                </h3>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Version" label-class="text-primary mb-1">
                      {{ emptyString( selectedHistory.version) }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Date" label-class="text-primary mb-1">
                      {{ $dayjs(selectedHistory.datetime).format('MMM DD, YYYY') }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Cycle Counter" label-class="text-primary mb-1">
                      {{ emptyString( selectedHistory.counter) }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Time" label-class="text-primary mb-1">
                      {{ $dayjs(selectedHistory.datetime).format('HH:mm:ss') }}
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>

                <h3 class="mb-4">
                  Program Setting
                </h3>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Program" label-class="text-primary mb-1">
                      {{ programName }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="DryTime" label-class="text-primary mb-1">
                      {{ emptyString(selectedHistory.dry_time,'m') }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Ster. Time" label-class="text-primary mb-1">
                      {{ emptyString(selectedHistory.ster_time,'m') }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Ster. Temp " label-class="text-primary mb-1">
                      {{ selectedHistory.ster_temp_min?
                        (selectedHistory.ster_temp_min+' - ' +selectedHistory.ster_temp_max):'-' }}
                    </b-form-group>
                  </b-col>
                </b-row>
                <hr>
                <h3 class="mb-4">
                  Summary
                </h3>
                <b-row>
                  <b-col cols="4">
                    <b-form-group label="Steri. time " label-class="text-primary mb-1">
                      {{ emptyString(selectedHistory.steri_time,'m') }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="8">
                    <b-form-group label="Steri. Temp " label-class="text-primary mb-1">
                      {{ selectedHistory.steri_temp_min?
                        (selectedHistory.steri_temp_min+' - ' +selectedHistory.steri_temp_max+'℃'):'-' }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Total time" label-class="text-primary mb-1">
                      {{ emptyString(selectedHistory.total_time) }}
                    </b-form-group>
                  </b-col>
                  <b-col cols="8">
                    <b-form-group label="Steri. Pres" label-class="text-primary mb-1">
                      {{ selectedHistory.steri_pres_min?
                        (selectedHistory.steri_temp_msteri_pres_minin+' - ' +
                          selectedHistory.steri_pres_max +' Kpa'):'-' }}
                    </b-form-group>
                  </b-col><b-col cols="4">
                    <b-form-group label="Cycle Status" label-class="text-primary mb-1">
                      {{ selectedHistory.status==='Complete'?'完成':selectedHistory.status }}
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="6">
                <h3 class="mb-4">
                  Data Summary
                </h3>
                <b-textarea readonly />
              </b-col>
            </b-row>
            <hr>
            <h3 class="mb-4">
              Instrument tracking
            </h3>
            <b-row>
              <b-col cols="6">
                <b-form-group label="Pre ID" label-class="text-primary mb-1">
                  {{ selectedHistory.tracking_pre_id }}
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Post ID" label-class="text-primary mb-1">
                  {{ selectedHistory.tracking_post_id }}
                </b-form-group>
              </b-col>
              <b-col cols="6" class="pl-0">
                <b-table
                  class="table-lite"
                  borderless
                  :items="selectedHistory.tracking_pre_items"
                  :fields="trackingFields"
                >
                  <template #head(number)>
                    Item Number
                  </template>
                  <template #head(time)>
                    <span class="caption-th">hh:mm</span>
                  </template>
                </b-table>
              </b-col>
              <b-col cols="6" class="pl-0">
                <b-table
                  class="table-lite"
                  borderless
                  :items="selectedHistory.tracking_post_items"
                  :fields="trackingFields"
                >
                  <template #head(number)>
                    Item Number
                  </template>
                  <template #head(time)>
                    <span class="caption-th">hh:mm</span>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </template>
        </div>
      </b-col>
    </b-row>
    <!-- <b-button variant="secondary" class="btn-action text-primary" @click="onCreateClick">
      <font-awesome-icon :icon="['fas', 'plus']" fixed-width />
    </b-button>
    <create-cycle-history-modal ref="createHistoryModal" /> -->
  </div>
</template>

<script>
import { getClinicDetail } from '@/api/clinic'
import { getAutoclaveSpecifyList } from '@/api/autoclave'
import { getSpecifyist, getCycleById } from '@/api/cycle'
import countryList from '@/common/countryList'
import cycleTypeList from '@/common/cycleTypeList'
import facilityList from '@/common/facilityList'
import modelList from '@/common/modelList'
import warrantyList from '@/common/warrantyList'
import NamePagination from '@/components/common/namePagination'
// import createCycleHistoryModal from '@/components/partner/createCycleHistoryModal'

export default {
  name: 'ClinicDetail',
  components: {
    NamePagination
    // createCycleHistoryModal
  },
  props: {
    id: {
      type: Number,
      required: true
    },
    partnerId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      clinicData: {},

      currentPage: 1,
      autoclaveList: [],
      detailList: [],
      historyList: [],
      searchDate: '',
      selectedHistory: null,
      selectedHistoryIndex: 0
    }
  },
  computed: {
    country () {
      return countryList.find(c => c.value === this.clinicData.clinic_country)?.text
    },
    facility () {
      return facilityList.find(c => c.value === this.clinicData.facility)?.text
    },
    currentAutoclave () {
      const data = { ...this.autoclaveList[this.currentPage - 1] }
      data.model = modelList.map(p => p.options).flat().find(a => a.value === data.model)?.text
      data.warranty = warrantyList.find(item => item.value === data.warranty)?.text
      return data
    },
    fields () {
      return ['datetime', 'time', 'program_name', 'status']
    },
    trackingFields () {
      return ['number', 'time']
    },
    filterHistoryList () {
      const searchDate = this.searchDate ? this.$dayjs(this.searchDate) : null
      return this.historyList.reduce((list, record) => {
        if (this.searchDate && !this.$dayjs(record.end_date).isSame(searchDate)) return list

        list.push(record)
        return list
      }, [])
    },
    sortHistoryList () {
      return this.historyList.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    },
    historyDetailTitle () {
      const formated = this.$dayjs(this.selectedHistory.datetime).format('MMM D, YYYY HH:mm:ss')
      return formated
    },
    programName () {
      if (this.selectedHistory.type === 0) return this.emptyString(this.selectedHistory.program)
      return cycleTypeList.find(m => m.value === this.selectedHistory.type)?.text
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.getClinicData()
      await this.getAutoclaveSpecifyList()
      await this.getHistoryList(this.currentAutoclave.id)
    },
    getClinicData () {
      return getClinicDetail(this.id).then(res => {
        this.clinicData = res.data.data.clinic
      })
    },
    getAutoclaveSpecifyList () {
      return getAutoclaveSpecifyList(this.id).then(res => {
        this.autoclaveList = res.data.data
      })
    },
    getHistoryList (id) {
      return getSpecifyist(id).then(res => {
        this.historyList = res.data.data
        this.detailList = this.historyList.filter(h => h.type === 0 || h.type === 10 || h.type === 11 || h.type === 12)
        // console.log(this.detailList)
      })
    },
    getProgramName (type, programname) {
      if (type === 0) return this.emptyString(programname)
      return cycleTypeList.find(m => m.value === type)?.text
    },
    async  onPageChange (page) {
      const autoclave = this.autoclaveList[page - 1]
      this.selectedHistory = null
      this.selectedHistoryIndex = 0
      await this.getHistoryList(autoclave.id)
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onRowSelected (item) {
      if (item.type === 0 || item.type === 10 || item.type === 11 || item.type === 12) {
        this.selectedHistoryIndex = this.detailList.findIndex(i => i.id === item.id)
        this.getHistory(item.id)
      }
    },
    onHistoryDetailChange () {
      const selectedId = this.detailList[this.selectedHistoryIndex].id
      this.getHistory(selectedId)
      // const data = { ...this.historyList[this.selectedHistoryIndex] }
      // this.selectedHistory = data.detail
    },
    getHistory (id) {
      getCycleById(id).then(res => {
        this.selectedHistory = res.data.data.cycle
      })
    },
    onCreateClick () {
      this.$refs.createHistoryModal.show(this.autoclaveList)
    },
    emptyString (val, unit = '') {
      return !val ? '-' : val + unit
    }
  }
}
</script>
<style scoped>
.b-table-top-row td, .caption-th{
  font-size: 12px;
  color:rgba(84, 90, 93, 0.7);
}
.step-td{
  color:#0088d1;
  font-size: 12px;
  font-weight: 500;
}
.btn-back{
  position: absolute;
  left: 16px;
  top: 16px;
}
</style>
