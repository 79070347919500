import apiService from '@/common/api.service'

export function getAutoclaveSpecifyList (id) {
  return apiService({
    url: '/api/autoclave/list/specify',
    method: 'get',
    params: { id }
  })
}

export function getAutoclaveList () {
  return apiService({
    url: '/api/autoclave/list',
    method: 'get'
  })
}
